import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';

@Injectable({
  providedIn: 'root',
})
export class ReCaptchaService {
  constructor(private recaptchaV3Service: ReCaptchaV3Service) {}
  reCaptcha() {
    return this.recaptchaV3Service.execute('importantAction');
  }
}
